
import $ from 'jquery'


// Le package à appliquer pour les masques
export function masksPackage() {
  formatPhone()
  formatPostalCode()
}


// Le formatage du numéro de téléphone
function formatPhone() {
  $('.js-mask-phone').mask('000 000-0000')
}


// Le formatage du code postal
function formatPostalCode() {
  $('.js-mask-postal-code').mask('Z0Z 0Z0', {
    translation: {
      'Z': {
        pattern: /[A-Z, a-z]/
      }
    }
  })
}